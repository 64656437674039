/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 16, 2022 */


@font-face {
    font-family: 'open_sauce_two-bold';
    src: url('opensaucetwo-bold-webfont.woff2') format('woff2'),
         url('opensaucetwo-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'open_sauce_two-regular';
    src: url('opensaucetwo-regular-webfont.woff2') format('woff2'),
         url('opensaucetwo-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'open_sauce_two-black';
    src: url('opensaucetwo-black-webfont.woff2') format('woff2'),
         url('opensaucetwo-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'open_sauce_two-extrabold';
    src: url('opensaucetwo-extrabold-webfont.woff2') format('woff2'),
         url('opensaucetwo-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

